<template>
  <BaseButton
    :id="blok.id"
    v-editable="blok"
    :href="blok.url"
    :outlined="buttonOutlinedClass"
    :ghost="buttonGhostClass"
    :small="blok.small"
    :disabled="buttonDisabledClass"
    :target="blok.url && blok.url.target"
    :full-width="blok.is_full_width"
    :title="blok.text"
  >
    <template v-if="iconFilename" #icon>
      <img v-if="blok.icon" :src="blok.icon.filename" alt="" />
    </template>
    <template v-if="blok.text" #text>
      {{ blok.text }}
    </template>
  </BaseButton>
</template>

<script lang="ts" setup>
import type { SbButtonStoryblok } from '@/types'
interface Props {
  blok: SbButtonStoryblok
}

const props = defineProps<Props>()

const iconFilename = computed(() => {
  return props.blok.icon?.filename || null
})
const buttonOutlinedClass = computed(() => {
  return props.blok.options?.includes('is_outlined')
})
const buttonGhostClass = computed(() => {
  return props.blok.options?.includes('is_ghost')
})
const buttonDisabledClass = computed(() => {
  return props.blok.options?.includes('is_disabled')
})
</script>
